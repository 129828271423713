@import '__importable.scss';
.cta-button {
	svg {
		display: none;
	}
}

.base-frame-pdp-sidebar{
	position: relative;

	@media screen and (min-width: $media-lg) {
		padding-right: 3.2rem;
	}
}
